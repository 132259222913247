<template>
    <section class="ads-banner" v-if="banner || !isBannerReady">
        <template v-if="isBannerReady">
            <router-link :to="banner.link" :title="banner.title" :name="name">
                <img :src="banner.img" :alt="banner.title" :title="banner.title" />
            </router-link>
        </template>
    </section>
</template>

<script>
export default {
    props: {
        isBannerReady: Boolean,
        banner: Object,
        name: String
    }
}
</script>

<style lang="scss" scoped>
@import '@/theme/Variable.scss';

.ads-banner {
    a {
        display: block;
        margin: auto;

        img {
            display: block;
            width: 100%;
        }
    }
}
</style>