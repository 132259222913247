<template>
    <section class="store-search">
        <store-search-form
            :initKeyword="initKeyword"
            @searchStoreKeyword="searchStoreKeyword($event)"
            @searchStoreNearby="searchStoreNearby()">
        </store-search-form>
    </section>
</template>

<script>
import RouterPath from '@/router/path';
import StoreSearchForm from '@/components/gadget/StoreSearchForm';

export default {
    components: {
        StoreSearchForm
    },
    props: {
        initKeyword: String
    },
    methods: {
        searchStoreKeyword(data) {
            const param = [ 'k=' + data.keyword ].join('&');
            this.$router.push(RouterPath.STORE + '?' + param).catch(()=>{});
        },
        searchStoreNearby() {
            if (location.pathname === '/store') {
                location.reload();
            } else {
                this.$router.push(RouterPath.STORE).catch(()=>{});
            }
        }
    }
}
</script>

<style lang="scss" scoped>
@import '@/theme/Variable.scss';

.store-search {
    background-color: #444;
    background: url(/img/decoration-tire.jpg);
    background-size: cover;
    padding: 15px;
}
</style>