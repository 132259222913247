<template>
    <div class="box" :class="{ 'sponsor': store.isSponsor }">
        <router-link
            :to="routerPath.STORE + '/' + store.param"
            class="content-picture-with-overlay picture"
            target="_blank"
            :title="store.name">
            <div class="wrapper">
                <div class="sponsor-overlay" v-if="store.isSponsor">โฆษณา</div>
                <img :src="store.picture" :alt="store.name" :title="store.name" @error="helper.setNoImg($event, 'store')" />
                <div class="network-icon" v-if="store.networkData">
                    <img :src="store.networkData.icon" :title="store.networkData.title" :alt="store.networkData.title" class="logo" />
                </div>
            </div>
        </router-link>

        <div class="detail">
            <h2>
                <router-link
                    :to="routerPath.STORE + '/' + store.param"
                    :title="store.name"
                    target="_blank">
                    <img :src="store.networkData.icon" :title="store.networkData.title" :alt="store.networkData.title" class="network-logo" v-if="store.networkData" />
                    {{ store.name }}
                </router-link>
            </h2>

            <address>
                <div class="item">{{ store.shortAddress }}</div>
                <div class="item"><b-icon icon="clock" class="icon"></b-icon> {{ store.openTime ? store.openTime : '-' }}</div>
            </address>
            
            <!-- <div class="rating mt-1">
                <b-icon icon="star-fill"
                    class="icon"
                    :class="{ 'active': (index + 1) <= store.starRating }"
                    v-for="(star, index) in 5"
                    :key="index">
                </b-icon>
                <div class="users" v-if="store.totalUserRating">({{ store.totalUserRating }})</div>
            </div> -->
        </div>
    </div>
</template>

<script>
import RouterPath from '@/router/path';
import Helper from '@/services/helper';

export default {
    data() {
        return {
            routerPath: RouterPath,
            helper: Helper
        };
    },
    props: {
        store: {
            type: Object,
            required: true
        }
    }
}
</script>

<style lang="scss" scoped>
@import '@/theme/Variable.scss';

.box {
    background: #fff;
    border-bottom: 2px solid #ffd800;

    &.sponsor {
        border: 2px solid #ababab;
    }

    a {
        color: #000;
        display: block;
    }

    .picture {
        position: relative;
        overflow: hidden;
        padding-top: 74%;
        background: #eee;

        .sponsor-overlay {
            position: absolute;
            z-index: 1;
            bottom: 0;
            right: 0;
            padding: 4px 8px;
            font-size: 11px;
            color: #fff;
            background: #ababab;
        }

        .wrapper {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            display: flex;
            align-items: center;
            
            img {
                display: block;
                width: 100%;
            }

            .network-icon {
                position: absolute;
                top: 0;
                right: 5px;

                .logo {
                    width: 35px;
                    height: 35px;
                }
            }
        }
    }

    .detail {
        padding: 5px 5px 10px;
        position: relative;

        h2 {
            font-size: 13px;
            font-weight: bold;
            margin: 0;
            height: 39px;
            
            a {
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                overflow: hidden;
                text-overflow: ellipsis;
            }

            .network-logo {
                width: 13px;
                height: 13px;
                margin-right: 6px;
            }
        }

        address {
            font-size: 12px;
            color: #999;
            margin: 0;

            .item {
                display: -webkit-box;
                -webkit-line-clamp: 1;
                -webkit-box-orient: vertical;
                overflow: hidden;
                text-overflow: ellipsis;
            }

            .icon {
                margin-right: 5px;
                font-size: 10px;
            }
        }

        .rating {
            position: relative;
            display: flex;
            align-items: center;

            .icon {
                color: #bbb;
                margin-right: 3px;

                &.active {
                    color: #ffaa00;
                }
            }

            .users {
                font-size: 15px;
            }
        }
    }
}
</style>