<template>
	<div class="stores">
		<div class="container">
			<gadget-ads-banner :banner="topBanner" name="bannerPageStoreList" :isBannerReady="isContentReady"></gadget-ads-banner>

			<div class="title">
				<h1>ร้านยางใกล้ฉัน รวมร้านเปลี่ยนยาง ศูนย์บริการยางครบวงจรทั่วไทย</h1>
				<p>ค้นหาร้านยางรถยนต์ใกล้ฉัน ร้านจำหน่ายยาง ร้านเปลี่ยนยาง ศูนย์บริการรถยนต์ รวมร้านทั่วไทย ทุกจังหวัด เช็คราคายางรถยนต์จากทุกอำเภอ</p>
			</div>

			<section class="content-container mt-3">
				<div class="filter">
					<div class="loading" v-if="!isContentReady">
						<loader-content :isDisplay="true"></loader-content>
					</div>
					<template v-if="isContentReady">
						<div class="content-mobile-filter-modal" :class="{ 'active': isShowMobileFilter }" @click="toggleFilterMobile()">
							<div class="box" @click.stop>
								<perfect-scrollbar>
									<div class="filter-title">
										<b-icon icon="sliders" class="icon"></b-icon> กรองผลการค้นหา <b-icon icon="x" @click="toggleFilterMobile()" class="close-icon"></b-icon>
									</div>
									<filter-search
										v-if="filterOptions && tags"
										ref="filterSearch"
										:filterOptions="filterOptions"
										:tagSelections="tags"
										@filterResult="changeFilter($event)">
									</filter-search>
								</perfect-scrollbar>
								<div class="operation-panel">
									<div class="wrapper">
										<button class="btn btn-reset btn-outline-secondary" @click="resetFilter()">รีเซ็ต</button>
										<button class="btn btn-main d-xl-none" @click="toggleFilterMobile()">ดูสินค้า {{ totalResult }} รายการ</button>
									</div>
								</div>
							</div>
						</div>
					</template>
				</div>
				<div class="content-body">
					<gadget-alert-geolocation class="mb-2" :state="displayAlertGeolocation" v-if="displayAlertGeolocation > 0 && sortType === 'near'"></gadget-alert-geolocation>

					<store-searcher :initKeyword="helper.getQueryString('k')"></store-searcher>

					<div class="store">
						<div class="content-mobile-filter">
							<button @click="toggleFilterMobile()"><b-icon icon="sliders" class="filter-icon"></b-icon> กรองผลการค้นหา</button>
						</div>

						<div class="loading mt-3" v-if="!isStoreReady">
							<div class="item">
								<loader-content :isDisplay="true"></loader-content>
							</div>
						</div>
					</div>

					<gadget-sort-bar :sortOption="sortOption" :defaultOption="sortOption[0].id" @changeSort="changeSort($event)" class="mt-3 mb-3"></gadget-sort-bar>

					<template v-if="isStoreReady">
						<gadget-search-tag :tags="tags" @removeTag="removeTag($event)" class="mb-3" v-if="tags && tags.length > 0"></gadget-search-tag>
						<store-grid :stores="storeList"></store-grid>
						<paging
							@page="changePage($event)"
							:activePage="activePage"
							:totalPage="totalPage"
							class="mt-4 mb-3">
						</paging>
					</template>
				</div>
			</section>
		</div>
	</div>
</template>

<script>
import $ from 'jquery';
import MixinGeolocationContent from '@/mixins/geolocationContent';
import Helper from '@/services/helper';
import StoreService from '@/services/storeService';
import GadgetAdsBanner from '@/components/gadget/AdsBanner';
import GadgetAlertGeolocation from '@/components/gadget/AlertGeolocation';
import StoreSearcher from '@/components/store/StoreSearcher';
import FilterSearch from '@/components/gadget/FilterSearch';
import StoreGrid from '@/components/store/StoreGridList';
import GadgetSortBar from '@/components/gadget/SortBar';
import GadgetSearchTag from '@/components/gadget/SearchTag';

export default {
	mixins: [ MixinGeolocationContent ],
	components: {
		GadgetAdsBanner,
		GadgetAlertGeolocation,
		StoreSearcher,
		FilterSearch,
		StoreGrid,
		GadgetSortBar,
		GadgetSearchTag
	},
	data() {
		return {
			helper: Helper,
			isContentReady: false,
			isStoreReady: false,
			isShowMobileFilter: false,
			topBanner: null,
			filterOptions: null,
			filter: null,
			storeList: [],
			totalResult: 0,
			activePage: 1,
			totalPage: 0,
			tags: [],
			sortOption: [
				{ id: 'near', name: 'ร้านที่ใกล้' },
                { id: 'popular', name: 'ยอดนิยม' }
            ],
			sortType: 'near'
		};
	},
	mounted() {
        this.getContent();
    },
    methods: {
        async getContent() {
            this.isContentReady = false;
			
            const result = await StoreService.getLandingContent();

			if (result.data) {
				this.topBanner = result.data.ads;
				this.filterOptions = result.data.filter;

				await this.checkGeolocation();
				this.getStoreResult();
			}

			this.isContentReady = true;
        },
		async getStoreResult() {
            this.isStoreReady = false;

			const param = await this.setupParamStore();
			const result = await StoreService.searchStore(param);

			this.storeList = result.data.resultList;
			this.totalResult = result.data.totalResult;
			this.tags = this.adjustTags(result.data.tags);
			this.totalPage = result.data.totalPage;
			this.isStoreReady = true;
        },
		async setupParamStore() {
			const position = this.geolocationState === 'granted' ? await new Promise((resolve, reject) => {
				navigator.geolocation.getCurrentPosition(resolve, reject);
			}) : null;

			const param = {
				page: this.activePage,
				keyword: Helper.getQueryString('k'),
				latitude: position ? position.coords.latitude : null,
				longtitude: position ? position.coords.longitude : null,
				filter: this.filter,
				sort: this.sortType
			};

			return param;
		},
		adjustTags(data) {
			const tagText = data.filter(x => !x.text.match(/<img/));
			const tagImg = data
				.filter(x => x.text.match(/<img/))
				.map(x => ({...x, text: $(x.text).attr('title')}));

			return [...tagText, ...tagImg];
		},
		changeFilter(data) {
			this.filter = data.filter;
			this.changePage(1);
		},
		changePage(page) {
			this.activePage = page;
			this.getStoreResult();
		},
		changeSort(data) {
			this.sortType = data.value;

			if (this.sortType) {
				this.getStoreResult();
			}
		},
		toggleFilterMobile() {
			this.isShowMobileFilter = !this.isShowMobileFilter;
			this.$root.$emit('rootSetContentScrollable', this.isShowMobileFilter);
		},
		resetFilter() {
			this.$refs.filterSearch.resetFilter();
		},
		removeTag(data) {
			const tagIndex = this.tags.findIndex(x => x.value === data.item.value);
			this.tags.splice(tagIndex, 1);
			this.$refs.filterSearch.updateFilterSelection(this.tags);
		}
    },
	metaInfo() {
		const title = 'ร้านยางใกล้ฉัน รวมร้านเปลี่ยนยาง ศูนย์บริการยางครบวงจรทั่วไทย | YELLOWTiRE';
		const desc = 'ค้นหาร้านยางรถยนต์ใกล้ฉัน ร้านจำหน่ายยาง ร้านเปลี่ยนยาง ศูนย์บริการรถยนต์ รวมร้านทั่วไทย ทุกจังหวัด เช็คราคายางรถยนต์จากทุกอำเภอ';
		const image = location.origin + Helper.metaDefault().logo;
		
        return {
            title: title,
            meta: [
				{ vmid: 'author', name: 'author', content: Helper.metaDefault().author },
				{ vmid: 'publisher', name: 'publisher', content: Helper.metaDefault().publisher },
				{ vmid: 'keyword', name: 'keywords', content: Helper.metaDefault().keyword + ',ราคายางรถยนต์,ร้านยางรถยนต์ใกล้ฉัน,กรุงเทพ' },
                { vmid: 'desc', name: 'description', content: desc },
                { vmid: 'og-url', property: 'og:url', content: location.href },
                { vmid: 'og-title', property: 'og:title', content: title },
                { vmid: 'og-desc', property: 'og:description', content: desc },
                { vmid: 'og-type', property: 'og:type', content: 'website' },
                { vmid: 'og-image', property: 'og:image', content: image },
                { vmid: 'twitter-description', property: 'twitter:description', content: desc },
                { vmid: 'twitter-title', property: 'twitter:title', content: title },
                { vmid: 'twitter-image', property: 'twitter:image', content: image }
            ]
        }
	}
}
</script>

<style lang="scss" scoped>
@import '@/theme/Variable.scss';

.stores {
	padding: 15px 0;

	@media only screen and (max-width: $screenMedium) {
		padding-top: 0;
	}

	.title {
		margin: 20px 10px;
		text-align: center;

		&:after {
			content: "";
			display: block;
			width: 100px;
			height: 1px;
			border-bottom: 2px solid #cc0000;
			margin: 15px auto 0;
		}

		h1 {
			font-family: 'Open Sans', 'Kanit';
			font-size: 18px;
			font-weight: bold;
			line-height: 20px;
			color: #555;
			margin: 0;
		}

		p {
			font-size: 13px;
			line-height: 15px;
			color: #888;
			margin: 4px 0 0;
		}
	}

	.store {
		position: relative;

		@media only screen and (max-width: $screenExtraLarge) {
			padding-top: 20px;
		}

		.loading {
			.item {
				height: 300px;
			}
		}
	}
}
</style>