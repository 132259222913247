<template>
    <div class="form">
        <div class="group">
            <div class="input-form">
                <input type="text" placeholder="ใส่ชื่อถนน, ชื่อจังหวัด, ชื่อร้าน" v-model="keyword" @keyup.enter="searchStoreKeyword()" />
                <button class="btn-clear" @click="clearSearchKeyword()"><b-icon icon="x"></b-icon></button>
            </div>
            <button class="btn btn-main btn-search" @click="searchStoreKeyword()"><font-awesome-icon :icon="['fas', 'search']" /> ค้นหา</button>
        </div>
        <div class="group">
            <div class="input-form">
                <input type="text" class="d-none d-md-block" readonly value="ค้นหาตำแหน่งที่ใกล้ (เปิด Location บนอุปกรณ์)" />
                <input type="text" class="d-md-none" readonly value="ค้นหาตำแหน่งที่ใกล้" />
            </div>
            <button class="btn btn-main btn-search" @click="searchStoreNearby()"><font-awesome-icon :icon="['fas', 'map-marker-alt']" /> ค้นหา</button>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            keyword: ''
        };
    },
    props: {
        initKeyword: String
    },
    mounted() {
        this.keyword = this.initKeyword;
    },
    methods: {
        searchStoreKeyword() {
            this.$emit('searchStoreKeyword', { keyword: this.keyword ? this.keyword : '' });
        },
        async searchStoreNearby() {
            this.$emit('searchStoreNearby');
        },
        clearSearchKeyword() {
            this.keyword = '';
        }
    }
}
</script>

<style lang="scss" scoped>
@import '@/theme/Variable.scss';

.form {
    display: flex;
    align-items: center;

    @media only screen and (max-width: $screenSmall) {
        display: block;
    }

    .group {
        width: 50%;
        display: flex;
        align-items: center;

        @media only screen and (max-width: $screenSmall) {
            width: 100%;
        }

        & + .group {
            @media only screen and (max-width: $screenSmall) {
                margin-top: 10px;
            }
        }

        .input-form {
            flex: 1;
            margin-right: 5px;
            position: relative;

            input {
                display: block;
                width: 100%;
                height: 36px;
                border: none;
                background: $inputBackgroundColor;
                font-size: 15px;
                padding: 5px 15px;
                outline: none;

                @media only screen and (max-width: $screenSmall) {
                    width: 100%;
                    font-size: 12px;
                }

                &[readonly] {
                    border: none;
                    background: none;
                    color: #ccc;
                }
            }

            .btn-clear {
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                width: 28px;
                outline: none;
                background: #fff;
                border: none;
                color: #777;

                &:hover {
                    opacity: 1;
                }
            }
        }

        h2 {
            flex: 1;
            color: #fff;
            font-size: 15px;
            margin: 0;
            margin-right: 15px;
            text-align: right;
        }

        .btn-search {
            width: 168px;
            height: 36px;
            display: block;
            margin: auto;

            @media only screen and (max-width: $screenSmall) {
                width: 80px;
                font-size: 13px;
            }
        }
    }
}
</style>