<template>
    <section class="store-grid">
        <div class="store"
            :class="grid"
            v-for="(item, index) in stores"
            :key="index">
            <store-box :store="item"></store-box>
        </div>

        <div class="no-data" v-if="stores.length === 0">
			<b-icon icon="box-seam"></b-icon>
            <span>ไม่พบร้านค้าที่กำลังค้นหา</span>
		</div>
    </section>
</template>

<script>
import StoreBox from '@/components/store/StoreBox';

export default {
    components: {
        StoreBox
    },
    props: {
        stores: {
            type: [ Array, Object ],
            required: true
        },
        grid: {
            type: String,
            required: false,
            default: () => ''
        }
    }
}
</script>

<style lang="scss" scoped>
@import '@/theme/Variable.scss';

.store-grid {
    @include gridContentWrapper(0 -5px);

    .store {
        display: block;
        padding: 0 5px 10px;
        width: 20%;

        @media only screen and (max-width: $screenLarge) {
            width: 25%;
        }

        @media only screen and (max-width: $screenSmall) {
            width: 33.33333%;
        }

        @media only screen and (max-width: $screenExtraSmall) {
            width: 50%;
        }

        &.column-6 {
            width: 16.6667%;

            @media only screen and (max-width: $screenMedium) {
                width: 33.33333%;
            }

            @media only screen and (max-width: $screenExtraSmall) {
                width: 50%;
            }
        }
    }
}

.no-data {
    margin: auto;
}
</style>